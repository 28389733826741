import React from 'react';

const Card = ({ title, onClick }) => {
  return (
    <div className="card" onClick={onClick} style={{ cursor: 'pointer', padding: '20px', textAlign: 'center', color: '#fff', border: '1px solid #222', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', margin: '10px', background: 'linear-gradient(to bottom, #222 90%, #fd5906 100%)' }}>
      <h3>{title}</h3>
    </div>
  );
};

export default Card;
