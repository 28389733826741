import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Signup from './components/Signup';
import Home from './components/Home';
import BoletoList from './components/BoletoList';
import Perfil from './components/Perfil';
import ValidaAssociado from './components/ValidaAssociado';
import ProtectedLayout from './components/ProtectedLayout';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        {/* Define a rota inicial como Login */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/home" element={<ProtectedLayout><Home /></ProtectedLayout>} />
        <Route
          path="/boletos"
          element={<ProtectedLayout><BoletoList cpfAssociado={localStorage.getItem('cpfAssociado')} token={localStorage.getItem('token')} /></ProtectedLayout>}
        />
        <Route
          path="/perfil"
          element={<ProtectedLayout><Perfil cpfAssociado={localStorage.getItem('cpfAssociado')} token={localStorage.getItem('token')} /></ProtectedLayout>}
        />
        <Route path="/ValidaAssociado/:codigo_associado" element={<ValidaAssociado />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
