import React, { useState } from 'react';
import { Container, TextField, Button, Card, CardContent, Typography, CircularProgress  } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleSignup = async () => {
    setLoading(true);
    if (!isValidEmail(email)) {
      setError('Email inválido');
      return;
    }
    try {
      const response = await axios.post('https://cliente.protegebeneficio.com.br/api/v1/signup.php', {
        name,
        cpf,
        email,
        password
      });

      if (response.data.success) {
        toast.success('Cadastro bem-sucedido! Redirecionando para login...');

        setTimeout(() => {
          navigate('/login');
        }, 5000);
      } else {
        toast.error(response.data.message || 'Erro ao cadastrar. Tente novamente.');
      }
    } catch (err) {
      console.error("Erro ao fazer cadastro:", err);
      toast.error('Erro ao fazer cadastro. Tente novamente.');
    }
    setLoading(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSignup();
    }
  };
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(to bottom, #ddd 95%, #fd5906 100%)',
        //backgroundImage: `url("/background.webp")`, // Imagem de fundo
        //backgroundSize: 'cover',
        //backgroundPosition: 'center',
        //backgroundRepeat: 'no-repeat'
      }}
    >
      <Container maxWidth="sm">
        <Card>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
              <img
                src="logolaranja.jpg"
                alt="Protege Logo"
                style={{ width: '200px', height: 'auto' }}
              />
            </div>
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 20 }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <InputMask
              mask="999.999.999-99"
              value={cpf}
              onChange={(e) => setCpf(e.target.value)}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  label="CPF"
                  variant="outlined"
                  fullWidth
                  style={{ marginBottom: 20 }}
                />
              )}
            </InputMask>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 20 }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!isValidEmail(email) && email.length > 0}
              helperText={!isValidEmail(email) && email.length > 0 ? 'Email inválido' : ''}
            />
            <TextField
              label="Senha"
              variant="outlined"
              type="password"
              fullWidth
              style={{ marginBottom: 20 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            {error && <Typography color="error">{error}</Typography>}

            <Button
              variant="contained"
              fullWidth
              style={{
                background: 'linear-gradient(to bottom, #424651 75%, #fd5906 100%)',
                color: '#fff',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={handleSignup}
              disabled={loading} // Desativa o botão enquanto está carregando
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Registrar'}
            </Button>
            {/* Link para Registro */}
            <Typography variant="body2" align="center" style={{ marginTop: 15 }}>
              Já tem uma conta? <Link to="/login" style={{ color: '#fd5906', textDecoration: 'none' }}>Faça Login</Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} />
    </div >
  );
};

export default Signup;
