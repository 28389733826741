import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Mapeamento das rotas para os nomes das páginas
const pageTitles = {
    '/': 'Portal do Cliente',
    '/boletos': 'Boletos',
    '/cartao-associado': 'Cartão Associado',
    '/outra-pagina': 'Outra Página' // Adicione outras rotas conforme necessário
};

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();

    // Determina se estamos na página inicial
    const isHomePage = location.pathname === '/';

    // Obtem o título da página com base na rota atual
    const pageTitle = pageTitles[location.pathname] || 'Portal do Cliente';

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('cpfAssociado');
        navigate('/login');
    };

    const handleBack = () => {
        navigate(-1); // Volta para a última página acessada
    };

    const [logoSize, setLogoSize] = useState(window.innerWidth < 768 ? '80px' : '120px');

    useEffect(() => {
        const handleResize = () => {
            setLogoSize(window.innerWidth < 768 ? '80px' : '120px');
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <AppBar
            position="fixed"
            sx={{ background: 'linear-gradient(to top, #222 98%, #fd5906 100%)' }}
        >
            <Toolbar>
                {!isHomePage && (
                    <IconButton edge="start" color="inherit" onClick={handleBack} aria-label="voltar">
                        <ArrowBackIcon />
                    </IconButton>
                )}

                <img
                    src="/logobranca.png"
                    alt="Logo"
                    style={{ width: logoSize }}
                />


                {/* Contêiner flexível para centralizar o título */}
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h6" align="center">
                        {pageTitle}
                    </Typography>
                </Box>

                <Button color="inherit" onClick={handleLogout}>Sair</Button>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
