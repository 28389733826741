import React, { useState } from 'react';
import { Container, TextField, Button, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [identifier, setIdentifier] = useState(''); // Pode ser CPF ou Email
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, ''); // Remove qualquer caractere que não seja número
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); // Formata o CPF
  };

  const handleLogin = async () => {
    setLoading(true);
    let formattedIdentifier = identifier;

    if (/^\d{11}$/.test(identifier.replace(/\D/g, ''))) {
      formattedIdentifier = formatCPF(identifier);
    }
    try {
      const response = await axios.post('https://cliente.protegebeneficio.com.br/api/v1/login.php', {
        identifier: formattedIdentifier,
        password
      });

      if (response.data.success) {
        toast.success('Login bem-sucedido!');
        localStorage.setItem('cpfAssociado', identifier);
        localStorage.setItem('token', response.data.token);
        setTimeout(() => {
          navigate('/home');
        }, 2000);
      } else {
        toast.error(response.data.message || 'Erro ao Logar. Tente novamente.');
      }
    } catch (err) {
      console.error("Erro ao fazer login:", err);
      toast.error('Erro ao fazer login. Tente novamente.');
    }
    setLoading(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(to bottom, #ddd 95%, #fd5906 100%)',
        position: 'fixed',
        width: '100%',
        height: '100%'
      }}
    >
      <Container
        maxWidth="xs"
        style={{
          padding: '0 10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          overflow: 'auto',
        }}
      >
        <Card style={{ padding: '10px', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', maxHeight: '90vh', overflowY: 'auto' }}>
          <CardContent>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
              <img
                src="logolaranja.jpg"
                alt="Protege Logo"
                style={{ width: '150px', height: 'auto' }}
              />
            </div>
            <TextField
              label="CPF ou Email"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 15 }}
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value)}
            />
            <TextField
              label="Senha"
              variant="outlined"
              type="password"
              fullWidth
              style={{ marginBottom: 15 }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />

            <Button
              variant="contained"
              fullWidth
              style={{
                background: 'linear-gradient(to bottom, #424651 75%, #fd5906 100%)',
                color: '#fff',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onClick={handleLogin}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Entrar'}
            </Button>

            <Typography variant="body2" align="center" style={{ marginTop: 15 }}>
              Não tem uma conta? <Link to="/signup" style={{ color: '#fd5906', textDecoration: 'none' }}>Registre-se</Link>
            </Typography>
          </CardContent>
        </Card>
      </Container>
      <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} />
    </div>
  );
};

export default Login;
