import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Typography } from '@mui/material';
import axios from 'axios';
import { QRCodeCanvas } from 'qrcode.react';
import html2canvas from 'html2canvas';

const Perfil = () => {
    const [associado, setAssociado] = useState(null);

    useEffect(() => {
        const cpf = localStorage.getItem('cpfAssociado');

        const fetchAssociado = async () => {
            if (cpf) {
                const response = await axios.get(`https://cliente.protegebeneficio.com.br/api/v1/ValidaAssociado.php?cpf=${cpf}&modo=cpf`);
                setAssociado(response.data);
            } else {
                console.error("CPF não encontrado no localStorage");
            }
        };

        fetchAssociado();
    }, []);

    const downloadImage = () => {
        const cardWrapper = document.querySelector("#card-wrapper");

        html2canvas(cardWrapper, { backgroundColor: null }).then(canvas => {
            const link = document.createElement("a");
            link.download = "cracha_associado.png";
            link.href = canvas.toDataURL("image/png");
            link.click();
        });
    };

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                background: 'linear-gradient(to bottom, #ddd 95%, #fd5906 100%)',
                paddingTop: window.innerWidth < 768 ? '56px' : '64px',
            }}
        >
            {/* Wrapper com fundo branco para garantir que a exportação tenha fundo branco */}
            <div id="card-wrapper" style={{ backgroundColor: '#fff', padding: '20px', borderRadius: '8px' }}>
                <Card style={{ width: '400px', textAlign: 'center', padding: '10px', margin: '0 auto', boxShadow: 'none' }}>
                    {associado ? (
                        <>
                            <CardContent>
                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                    <img
                                        src="logolaranja.jpg"
                                        alt="Protege Logo"
                                        style={{ width: '150px', height: 'auto' }}
                                    />
                                </div>
                                <Typography variant="h4">{associado.descricao_situacao}</Typography>
                                <Typography variant="h6">{associado.nome}</Typography>
                                <Typography variant="body2" style={{ marginTop: '10px' }}>CPF: {associado.cpf}</Typography>
                                <Typography variant="body2" style={{ marginTop: '10px' }}>Código Associado: {associado.codigo_associado}</Typography>

                                <Typography variant="body2" style={{ marginTop: '10px' }}>
                                    <strong>Veículos Ativos:</strong>
                                </Typography>
                                {associado.veiculos && associado.veiculos.length > 0 ? (
                                    associado.veiculos
                                        .filter(veiculo => veiculo.codigo_situacao === '1')
                                        .map((veiculo, index) => (
                                            <Typography key={index} variant="body2">Placa: {veiculo.placa}</Typography>
                                        ))
                                ) : (
                                    <Typography variant="body2">Nenhum veículo ativo associado</Typography>
                                )}
                                {associado.descricao_situacao === 'ATIVO' && (
                                    <>
                                        <QRCodeCanvas value={`https://cliente.protegebeneficio.com.br/ValidaAssociado/${associado.codigo_associado}`} size={100} style={{ marginTop: '10px' }} />
                                    </>
                                )}
                            </CardContent>
                        </>
                    ) : (
                        <Typography>Carregando...</Typography>
                    )}
                </Card>
            </div>

            {/* Botão de exportação fora do Card */}
            <Button variant="contained" onClick={downloadImage} style={{ marginTop: '20px' }}>Exportar Crachá</Button>
        </div >
    );
};

export default Perfil;
