import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Header from './Header';

const ProtectedLayout = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    // Verifica se o token da sessão está ativo
    const isAuthenticated = !!localStorage.getItem('token');

    useEffect(() => {
        if (!isAuthenticated && location.pathname !== '/login' && location.pathname !== '/register') {
            navigate('/login'); // Redireciona para o login se não estiver autenticado
        }
    }, [isAuthenticated, location, navigate]);

    // Exibir o cabeçalho apenas se não estiver em login ou registro
    const shouldShowHeader = location.pathname !== '/login' && location.pathname !== '/register';

    return (
        <>
            {shouldShowHeader && <Header />}
            <div>{children}</div>
        </>
    );
};

export default ProtectedLayout;
