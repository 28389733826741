import React, { useEffect, useState, useCallback } from 'react';
import { Button, Container, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import axios from 'axios';
import { Refresh as RefreshIcon } from '@mui/icons-material';

// Função para calcular a data com base em um deslocamento de dias
const calculateDate = (daysOffset) => {
    const date = new Date();
    date.setDate(date.getDate() + daysOffset);
    return date.toLocaleDateString('pt-BR'); // Formato 'DD/MM/YYYY'
};

const BoletoList = () => {
    const cpf = localStorage.getItem('cpfAssociado');

    const [boletos, setBoletos] = useState([]);
    const [loading, setLoading] = useState(true);

    // Define fetchBoletos com useCallback para evitar dependência instável no useEffect
    const fetchBoletos = useCallback(async () => {
        setLoading(true);

        const dataVencimentoInicial = calculateDate(-160); // Data atual - 160 dias
        const dataVencimentoFinal = calculateDate(40); // Data atual + 40 dias

        try {
            const response = await axios.post('https://cliente.protegebeneficio.com.br/api/v1/listarBoletos.php', {
                cpf_associado: cpf,
                data_vencimento_original_inicial: dataVencimentoInicial,
                data_vencimento_original_final: dataVencimentoFinal
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                const boletosData = response.data;

                // Ordena os boletos
                const boletosOrdenados = boletosData.sort((a, b) => {
                    if (a.situacao_boleto === 'ABERTO' && b.situacao_boleto !== 'ABERTO') return -1;
                    if (a.situacao_boleto !== 'ABERTO' && b.situacao_boleto === 'ABERTO') return 1;
                    return new Date(b.data_vencimento_original) - new Date(a.data_vencimento_original);
                });

                setBoletos(boletosOrdenados);
            }
        } catch (error) {
            console.error("Erro ao buscar boletos:", error);
        } finally {
            setLoading(false);
        }
    }, [cpf]);

    useEffect(() => {
        fetchBoletos();
    }, [fetchBoletos]);

    const handleCopyLinhaDigitavel = (linhaDigitavel) => {
        navigator.clipboard.writeText(linhaDigitavel).then(() => {
            alert('Linha digitável copiada!');
        });
    };

    return (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(to bottom, #ddd 95%, #fd5906 100%)',
                paddingTop: window.innerWidth < 768 ? '56px' : '64px',
            }}
        >
            <Container maxWidth="md">
                <Typography variant="h4" gutterBottom>Lista de Boletos</Typography>

                <Button
                    variant="outlined"
                    style={{ color: '#222', borderColor: '#222', marginBottom: '20px' }}
                    onClick={fetchBoletos}
                    startIcon={<RefreshIcon />}
                >
                    Recarregar
                </Button>

                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                        <CircularProgress size={60} style={{ color: '#fd5906' }} />
                    </div>
                ) : (
                    boletos.length === 0 ? (
                        <Typography>Não há boletos disponíveis.</Typography>
                    ) : (
                        boletos.map((boleto, index) => (
                            <Card key={index} style={{ marginBottom: '20px' }}>
                                <CardContent>
                                    <Typography variant="h6">Data de Vencimento: {new Date(boleto.data_vencimento_original).toLocaleDateString()}</Typography>
                                    <Typography>Valor: R$ {parseFloat(boleto.valor_boleto).toFixed(2)}</Typography>
                                    <Typography>Situação: {boleto.situacao_boleto}</Typography>

                                    {boleto.situacao_boleto === 'ABERTO' &&
                                        (!boleto.linha_digitavel.includes("Não foi possível disponibilizar")) &&
                                        (!boleto.link_boleto.includes("Não foi possível disponibilizar")) && (
                                            <>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => handleCopyLinhaDigitavel(boleto.linha_digitavel)}
                                                    style={{ color: '#222', borderColor: '#222', marginTop: '10px', marginRight: '10px' }}
                                                >
                                                    Copiar Linha Digitável
                                                </Button>

                                                {boleto.link_boleto && (
                                                    <Button
                                                        variant="contained"
                                                        href={boleto.link_boleto}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ color: '#fff', backgroundColor: '#222', borderColor: '#222', marginTop: '10px' }}
                                                    >
                                                        Baixar Boleto
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                </CardContent>
                            </Card>
                        ))
                    )
                )}
            </Container>
        </div>
    );
};

export default BoletoList;
