import React from 'react';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Card from './Card'; // Importe o Card personalizado

const Home = () => {
  const navigate = useNavigate();

  // Configurações dos menus com as rotas correspondentes
  const menus = [
    { title: '2ª Via de Boleto', route: '/boletos' },
    { title: 'Cartão Associado', route: '/perfil' }
  ];

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(to bottom, #ddd 95%, #fd5906 100%)'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          {menus.map((menu, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Card 
                title={menu.title} 
                onClick={() => navigate(menu.route)} // Define a navegação para cada item
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Home;
