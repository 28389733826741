import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/'); // Redireciona para a Home
    }, [navigate]);

    return null; // Retorna null pois o redirecionamento ocorre imediatamente
};

export default NotFound;
